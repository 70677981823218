<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { usePlatform } from '@/composables/platform'
import Container from '@/components/Structure/Container/Container.vue'
import MenuTooltip from '@/components/Menu/MenuTooltip.vue'
import Tooltip from '@/components/partials/Tooltip.vue'
import Icon from '@/components/partials/Icon.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import LanguageSwitcherCircle from '@/pages/home/components/Navigation/LanguageSwitcherCircle.vue'

type Props = {
  menu: boolean
  outline?: boolean
  shareButton?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  menu: true,
})

const emit = defineEmits([ 'logo-click' ])

const platform = usePlatform()

async function share() {
  if (!navigator.share) return

  const data = {
    title: document.title,
    url: document.location.href,
  }

  if (!navigator.canShare(data)) {
    return
  }

  try {
    await navigator.share(data)
  } catch (e) {
    console.error(e)
  }
}

const route = useRoute()

const { t } = useI18n()
</script>

<template>
  <div :class="[ 'menu', { 'menu--outline': props.outline, 'menu--ios': platform === 'ios', 'menu--android': platform === 'android' } ]">
    <Container class="menu__menu-items">
      <div class="menu__back-button" @click="emit('logo-click')">
        <div class="menu__back-button-white">
          <Icon name="arrow-left-s-line-fat"
                class="menu__back-arrow"
                no-margin />
          <img src="/assets/img/brand/bistroo-logo.svg"
               class="menu__bistroo-logo"
               :alt="t('images.bistroo_logo')" />
        </div>
      </div>
      <div class="menu__right">
        <Button v-if="shareButton"
                type="secondary"
                class="menu__menu-button"
                icon="share-line"
                @click="share" />

        <LanguageSwitcherCircle v-if="route.name.includes('marketplace')" />

        <Tooltip class="menu__menu-tooltip" v-if="menu">
          <template #default="{ active }">
            <Button type="secondary"
                    class="menu__menu-button"
                    :icon="active ? 'cross-line' : 'menu-line'" />
          </template>
          <template #content>
            <MenuTooltip />
          </template>
        </Tooltip>
      </div>
    </Container>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/variables/variables.scss';
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/styling.scss';

.menu {
  $self: &;
  z-index: 80;
  position: absolute;
  top: 1.5rem;
  left: 0;
  width: 100%;
  padding: 0 1.5rem;

  &--ios {
    top: 4.5rem;
  }

  &--android {
    top: calc(env(safe-area-inset-top) + 1.5rem)
  }

  @include lg-up {
    display: none;
  }

  &__back-button {
    cursor: pointer;
    background-color: #fff;
    border-radius: 99999px;
    box-shadow: 0 0.25rem 0.25rem rgba(36, 46, 74, 0.25);
    display: flex;
    align-content: center;

    @include lg-up {
      display: none;
    }
  }

  &__back-button-white {
    padding: 0.7rem 1rem 0.8rem;
    background-color: #fff;
    border-radius: 99999px;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__right {
    display: flex;
    gap: 0.5rem;
  }

  &__bistroo-logo {
    width: 4.5rem;
    cursor: pointer;
    transform: translateY(-0.05rem);
  }

  &__menu-items {
    display: flex;
    justify-content: space-between;
  }

  &__menu-button {
    &__back-arrow:deep(.b-icon-icon svg) {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__menu-tooltip {
    &:deep(.tooltip__dropdown) {
      width: calc(100% - 2rem);
      height: max-content;
      border-radius: 2rem;
      right: 1rem;
      box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.2);
    }

    &:deep(.tooltip__inner),
    &:deep(.menu-tooltip) {
      height: max-content;
    }
  }

  &--outline {
    #{$self}__back-button {
      @include gradient-button;
      box-shadow: none;
      padding: 2px;
    }

    &:deep(.b-icon-icon) {
      color: var(--color-brand-pink);
    }
  }
}
</style>
